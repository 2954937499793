.footer {
  background-color: #E9E2FF;
  line-height: 15px;
  box-shadow: 0px -2px 2px 1px rgba(0, 0, 0, 0.1);
}

.footer-shop-name {
  font-style: italic;
  margin-left: 19px;
}

.footer-social {
  font-size: 4rem;
}

.hours {
  font-weight: 600;
}

.walk-in {
  font-style: italic;
  margin-bottom: 0px;
}

#address-two {
  margin-left: 20px;
}

#footer-logo {
  width: 150px;
}



@media screen and (max-width: 768px) {
  .footer-heading {
    text-align: center;
  }
}

@media screen and (max-width: 350px) {
  .footer-social {
    font-size: 3rem;
  }
}

