.menu-arrow {
  color: #6f5e8f;
  font-size: 3rem;
  opacity: .40;
  display: none;
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: 10%;
}

.arrow-left {
  position: absolute;
  top: 50%;
  left: 10%;
}

.menus {
  background-color: #fff;
  border-radius: 5px;
}

.nav-tabs {
  border: none;
}

.nav-item {
  font-family: "Marcellus SC";
  font-size: 1.3rem;
}





  @media screen and (max-width: 530px) {
    .nav {
        white-space: nowrap;
        display:block!important;
        flex-wrap: nowrap;
        max-width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
      }

      .nav-item {
        font-size: 1rem;
      }

      .nav-link {
        font-size: 1rem;
        padding: 8px 8px;
        margin: 0px 5px 0px 5px;
      }
      
      .nav li {
        display: inline-block
      }

      .menu-arrow {
        display:initial;
      }
  }


