nav {
  background-color: #e9e2ff;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.nav-links {
  display: flex;
  flex-direction: row;
}

.nav-link {
  margin: 0 10px 0 10px;
}

.navbar a {
  padding: 0px;
  font-family: "Marcellus SC";
  font-size: 1.3rem;
  font-weight: 500;
}

.triangle {
  display: block;
  height: 0px;
  width: 0px;
  border: 10px solid transparent;
  border-top-color: #e9e2ff;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 10px);
}

#navbar-logo {
  width: 450px;
}

@media screen and (max-width: 1024px) {
  #navbar-logo {
    width: 330px;
  }
}

@media screen and (max-width: 400px) {
  .navbar a {
    font-size: 1rem;
    margin: 0 8px 0 8px;
  }
}

@media screen and (max-width: 320px) {
  #navbar-logo {
    width: 300px;
  }
}
