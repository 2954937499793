.image-modal {
    z-index: 3000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);
  }

  .image-modal-content {
    position: relative;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    outline: 0;
    width: 90%;
    animation: fade-in .5s;
  } @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .modal-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    max-height: 800px;
  }