a {
  color: #6f5e8f;
  text-decoration: none;
}

a:hover {
  color: #957cc4;
  text-decoration: none;
}

body {
  background-color: #f8f8ff;
  font-family: 'Kumbh Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Marcellus SC";
}


.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.page {
  flex: 1 0 auto;
  width: 100%;
}
