#embedded-map {
  width: 100%;
  min-height: 450px;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .contact-section {
    margin: 0px !important;
  }
}
