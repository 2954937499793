.gallery-spinner {
  position: relative;
  top: 130px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #fec5bb;
}

.gallery-thumbnail-spinner {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  box-shadow: 5px 2px 2px rgb(148, 148, 148);
}

.menu-spinner {
  position: relative;
  top: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #fec5bb;
}

.menu-image-spinner {
  width: 100%;
  height: 300px;
}
