.hero-button {
  position: relative;
  top: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #6f5e8f;
}

.hero-button:hover {
  background-color: #957cc4;
}

.hero-button a {
  color: #fff;
}

.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("./assets/hero.jpeg");
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-services {
  padding-top: 25px;
  padding-bottom: 50px;
}

.home-service-thumb {
  position: relative;
  height: 200px;
  width: 200px;
  z-index: -1;
}

.home-service-thumb-gradient {
  display: flex;
  width: 200px;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  );
}

.home-service-thumb-gradient:hover {
  background-image: none;
  /* background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); */
}

.text-section {
  margin-top: 25px;
}

.text-section p {
    line-height: 1.7;
}

.text-section-image {
    margin-top: 1rem;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.5s;
}



